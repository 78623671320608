import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "@/components/Login"
import store from './store'

Vue.use(VueRouter)

const Home = () => import("./components/Home")
const Signup = () => import("./components/Signup")
const UploadSpreadsheet = () => import("./components/UploadSpreadsheet")
const SingleStudentForm = () => import("./components/SingleStudentForm")
const StudentsList = () => import("./components/StudentsList")
const Payment = () => import("./components/Payment")

const checkEligibility = to => {
  switch (to) {
    case '/enroll/spreadsheet':
    case '/enroll/single':
      return store.getters.canEnroll && store.getters.isAdviserActive
    case '/payment':
      return store.getters.canSubmitPayment && store.getters.isAdviserActive
    case '/list':
      return store.getters.isAdviserActive
    default:
      return true
  }
}

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAdviserSignedIn && !store.getters.isAdminSignedIn) {
    next()
    return
  }
  next('/home')
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAdviserSignedIn) {
    store.dispatch('updatePermissions').then(() => {
      if (checkEligibility(to.path)) {
        next()
      } else {
        next(from.path)
      }
    })
    return
  }
  if (store.getters.isAdminSignedIn) {
    next('/admin')
    return
  }
  if (from !== '/login') {
    next('/login')
  }
}

const ifAdminAuthenticated = (to, from, next) => {
  if (store.getters.isAdminSignedIn) {
    next()
    return
  }
  next('/admin/login')
}

const router = new VueRouter({
  scrollBehavior() {
    return {x: 0, y: 0}
  },
  routes: [
    {
      path: '/',
      name: 'index',
      component: () => import('@/components/Index'),
      meta: {title: ''}
    },
    {
      path: '/regimento',
      name: 'regimento',
      component: () => import('./components/Regimento'),
      meta: {title: 'Regimento'}
    },
    {
      path: '/premiacao',
      name: 'premiacao',
      component: () => import('./components/Premiacao'),
      meta: {title: 'Premiação'}
    },
    // {
    //   path: '/provas',
    //   name: 'provas',
    //   component: () => import('./views/Provas'),
    //   meta: {title: 'Provas'}
    // },
    // {
    //     path: '/published-students',
    //     name: 'publishedStudents',
    //     component: () => import('./components/PublishedStudentList'),
    //       meta: {title: 'Lista de inscritos'}
    // },
    {
      path: '/login',
      name: 'login',
      component: Login,
      beforeEnter: ifNotAuthenticated,
      meta: {title: 'Entrar'}
    },
    {
      path: '/signup',
      name: 'signup',
      component: Signup,
      beforeEnter: ifNotAuthenticated,
      meta: {title: 'Registrar Coordenador'}
    },
    {
      path: '/home',
      name: 'home',
      component: Home,
      beforeEnter: ifAuthenticated,
      meta: {title: 'Início'}
    },
    {
      path: '/enroll/spreadsheet',
      name: 'enrollSpreadsheet',
      component: UploadSpreadsheet,
      beforeEnter: ifAuthenticated,
      meta: {title: 'Inscrever planilha'}
    },
    {
      path: '/enroll/single',
      name: 'enrollSingle',
      component: SingleStudentForm,
      beforeEnter: ifAuthenticated,
      meta: {title: 'Inscrever aluno'}
    },
    {
      path: '/list',
      name: 'studentsList',
      component: StudentsList,
      beforeEnter: ifAuthenticated,
      meta: {title: 'Alunos inscritos'}
    },
    {
      path: '/payment',
      name: 'payment',
      component: Payment,
      beforeEnter: ifAuthenticated,
      meta: {title: 'Pagamento'}
    },
    {
      path: '/admin/login',
      name: 'adminLogin',
      component: () => import("./components/admin/AdminLogin"),
      beforeEnter: ifNotAuthenticated,
      meta: {title: 'Admin Login'}
    },
    {
      path: '/admin',
      name: 'admin',
      component: () => import("./components/admin/AdminApp"),
      children: [
        {
          path: '',
          redirect: {path: 'dashboard'}
        },
        {
          path: 'dashboard',
          name: 'adminDashboard',
          component: () => import("./components/admin/AdminDashboard"),
          beforeEnter: ifAdminAuthenticated,
          meta: {title: 'Admin: Dashboard'}
        },
        {
          path: 'app/permission',
          name: 'adminPermissions',
          component: () => import("./components/admin/AdminAppPermissions"),
          beforeEnter: ifAdminAuthenticated,
          meta: {title: 'Admin: Permissões'}
        },
        {
          path: 'enrolled/advisers',
          name: 'adminEnrolledAdvisers',
          component: () => import("./components/admin/EnrolledAdvisers"),
          beforeEnter: ifAdminAuthenticated,
          meta: {title: 'Admin: Coordenadores cadastrados'}
        },
        {
          path: 'payment',
          name: 'adminPaymentProofList',
          component: () => import("./components/admin/PaymentProofList"),
          beforeEnter: ifAdminAuthenticated,
          meta: {title: 'Admin: Pagamento'}

        }
      ]
    },
    {
      path: '*',
      name: 'notFound',
      component: () => import("./components/NotFound"),
      meta: {title: 'Oops...'}
    }
  ],
  mode: 'history'
})

router.beforeEach((to, from, next) => {
  const baseTitle = 'OCM - Olimpíada Cearense de Matemática 2024'
  if (!['index'].includes(to.name)) {
    document.title = `${to.meta.title} | ${baseTitle}`
  } else {
    document.title = baseTitle
  }
  next()
})

export default router
